var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tbody', [!_vm.rows.records ? _c('tr', [_c('td', {
    attrs: {
      "colspan": _vm.colSpan,
      "align": "center"
    }
  }, [_vm._v("No records found.")])]) : _vm._e(), _vm._l(_vm.rows.records, function (row, index) {
    return _c('tr', {
      key: index,
      class: row.is_deletable == 1 ? 'danger-row' : ''
    }, [_c('td', [_c('span', {
      staticClass: "date"
    }, [_vm._v(_vm._s(row.entry_date))])]), _c('td', [_c('span', {
      staticClass: "agent-name"
    }, [_vm._v(_vm._s(row.agent_name))])]), _c('td', [_c('span', {
      staticClass: "name"
    }, [_vm._v(_vm._s(row.username))])]), _c('td', [_c('span', {
      staticClass: "website"
    }, [_vm._v(" " + _vm._s(row.website))])]), _c('td', [_c('span', {
      staticClass: "progress-complete"
    }, [_vm._v(_vm._s(row.remarks))])]), _c('td', [_c('div', {
      staticClass: "action-icons"
    }, [_c('custom-button', {
      attrs: {
        "title": "Complete",
        "iconClass": "fab fa-wpforms"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.completeAction(row);
        }
      }
    }), row.email ? _c('custom-button', {
      attrs: {
        "title": "Send Email",
        "iconClass": "fas fa-envelope"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.sendEmail(row);
        }
      }
    }) : _vm._e(), row.is_deletable == 1 ? [_c('delete-button', {
      on: {
        "onClicked": function onClicked($event) {
          return _vm.deleteRow(row);
        }
      }
    })] : _vm._e()], 2)])]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }