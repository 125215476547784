<template>
  <tbody>
    <tr v-if="!rows.records">
      <td :colspan="colSpan" align="center">No records found.</td>
    </tr>
    <tr
      v-for="(row, index) in rows.records"
      :key="index"
      :class="row.is_deletable == 1 ? 'danger-row' : ''"
    >
      <td>
        <span class="date">{{ row.entry_date }}</span>
      </td>
      <td>
        <span class="agent-name">{{ row.agent_name }}</span>
      </td>
      <td>
        <span class="name">{{ row.username }}</span>
      </td>
      <td>
        <!-- <span class="website">
                    <a :href="row.weburl" target="__blank">
                        {{row.website}}
                    </a>
                  </span> -->
        <span class="website"> {{ row.website }}</span>
      </td>
      <td>
        <span class="progress-complete">{{ row.remarks }}</span>
      </td>
      <td>
        <div class="action-icons">
          <custom-button
            title="Complete"
            iconClass="fab fa-wpforms"
            @onClicked="completeAction(row)"
          />
          <custom-button
            v-if="row.email"
            title="Send Email"
            iconClass="fas fa-envelope"
            @onClicked="sendEmail(row)"
          />
          <template v-if="row.is_deletable == 1">
            <delete-button @onClicked="deleteRow(row)" />
          </template>
        </div>
      </td>
    </tr>
  </tbody>
</template>
<script>
import Api from "@/resource/Api";
export default {
  props: {
    rows: {
      required: true,
    },
    colSpan: {
      type: Number,
      required: false,
      default: 6,
    },
    type: {
      type: String,
      required: true,
    },
  },
  methods: {
    completeAction(row) {
      //currenty using same for both group and group
      window.open(row.weburl, "_blank");
    },
    deleteRow(row) {
      let url;
      switch (this.type) {
        case "group":
          url = `v2/delete-pending-group/${row.id}`;
          break;
        case "rep":
          url = `v2/delete-reps/${row.id}`;
          break;
        default:
          url = null;
      }
      this.$confirm({
        title: `Delete ${this.type}`,
        message: `Do you want to delete this ${this.type}?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            Api.delete(url)
              .then((res) => {
                let response = res.data.message;
                this.$notify(response);
                this.$emit("onSuccess");
              })
              .catch((err) => {
                this.$notify(err.response.data.message, "danger");
              })
              .finally(() => {
                loader.hide();
              });
          }
        },
      });
    },
    sendEmail(row) {
      let url;
      switch (this.type) {
        case "group":
          url = `v2/send-group-registration-completion-email/${row.id}`;
          break;
        case "rep":
          url = `v2/send-registration-completion-email/${row.id}`;
          break;
        default:
          url = null;
      }
      this.$confirm({
        title: "Continue Enrollment",
        message: `Do you want to send email to enroll - ${row.email}?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            Api.post(url)
              .then((res) => {
                let response = res.data.message;
                this.$notify(response);
              })
              .catch((err) => {
                this.$notify(err.response.data.message, "danger");
              })
              .finally(() => {
                loader.hide();
              });
          }
        },
      });
    },
  },
};
</script>